.btn {
  @apply flex flex-col text-center px-8 py-2 rounded-full flex-nowrap whitespace-nowrap text-nowrap h-auto uppercase no-underline text-center w-full md:w-fit text-base md:text-lg font-semibold hover:outline outline-offset-4 transition duration-300 ease-in-out;
}

.btn-primary {
  @apply bg-chi-bright-blue border-chi-bright-blue text-white hover:bg-chi-dark-blue;
}

.btn-secondary {
  @apply bg-chi-bright-orange;
}

.btn-link {
  @apply text-chi-bright-blue underline-offset-4 decoration-2 hover:underline decoration-chi-bright-orange text-base;
}