/* If you need to add @import statements, do so up here */
@import "./shared/button.css";
/* triggers frontend rebuilds */
/* uncomment for development */
@import "jit-refresh.css";

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
  transition: all 0.5s ease-in-out;
}

body {
  @apply text-white font-body text-base font-normal bg-chi-dark-blue;
}

@layer base {

  h1 { @apply text-3xl lg:text-4xl tracking-widest mb-8 font-display uppercase; }
  h2 { @apply text-xl lg:text-2xl xl:text-3xl tracking-widest mb-2 font-display uppercase; }
  h3 { @apply text-lg md:text-xl mb-8 uppercase; }
  p { margin-bottom: 1.25rem; }
}


@layer utilities {
  nav {
    .nav-item { @apply no-underline underline-offset-4 decoration-2 hover:underline decoration-chi-bright-orange; }
  }

  footer {
    @apply bg-chi-dark-blue;
  
    .credit {
      @apply w-full h-60 md:h-96 2xl:h-[500px] block bg-chi-dark-blue bg-footer bg-mobile bg-no-repeat bg-cover bg-center 2xl:bg-top relative;
      a {
        @apply right-10 bottom-10 absolute;
        img { @apply w-72 h-auto; }
      }
    }
  }

  .markdown {
    p { @apply last:mb-0 leading-relaxed font-normal; }
  }
}

.hero { @apply w-full md:h-auto bg-cover bg-no-repeat relative bg-hero bg-fixed bg-chi-dark-blue bg-mobile md:bg-desktop }

.newsletter-signup {
  @apply flex flex-col items-center justify-center md:w-fit xl:w-2/5 p-20 min-h-[400px] lg:min-h-[600px] xl:max-h-[900px] bg-chi-dark-blue/40 text-center backdrop-blur-xs;
  date { @apply drop-shadow-lg text-base lg:text-2xl md:text-lg tracking-tighter mb-2; }
  h2 { @apply text-3xl drop-shadow-lg tracking-widest mb-2 font-display uppercase; }
}

.location {
  @apply flex flex-col xl:flex-row items-center justify-center relative bg-chi-dark-blue pt-20;
  &:before {
    @apply bg-dark-blue h-24 w-full absolute -top-10;
    content: '';
  }
  
  .dosequis { @apply hidden xl:block xl:absolute  w-96 h-24 -top-16 right-0 bg-no-repeat bg-xx; }

  .bean {
    @apply h-full w-full max-w-[800px];
    .tablet { @apply hidden md:flex object-cover aspect-auto; }
    .mobile { @apply flex md:hidden object-cover aspect-auto w-full; }
  }

  .info {
    @apply md:p-8 xl:w-1/3 text-base p-8 md:px-16;
    h2 { @apply text-3xl drop-shadow-lg tracking-widest mb-2 font-display uppercase; }
    h3 { @apply text-xl mb-8; }
  }
}


.news {
  @apply bg-chi-dark-blue p-4 md:px-16 md:py-36 flex flex-col md:flex-row;
  .fish {
    @apply hidden md:flex md:w-full md:h-full md:absolute md:-top-44 md:-left-36 bg-no-repeat bg-fish-up;
  }
  h3 { @apply text-xl font-bold mb-4;}
}

#platinum { @apply bg-chi-platinum-blue text-white; }
#ruby { @apply bg-chi-red/75 text-white; }
#gold { @apply bg-chi-light-orange text-chi-dark-blue; }
#silver { @apply bg-chi-silver text-chi-dark-blue; }
#other { @apply bg-chi-light-blue text-chi-dark-blue; }

.speakers {
  #sessionize.sessionize-wrapper .sz-speakers--wall {
    @apply !grid md:!grid-cols-2 lg:!grid-cols-3 xl:!grid-cols-4 !gap-8;
    .sz-speaker { @apply !m-0 !w-full !h-full !p-0; }
  }
  #sessionize.sessionize-wrapper .sz-speaker.sz-speaker--compact {
    .sz-speaker__photo{
      @apply !m-0 !w-full !h-fit !border-0;
      img { @apply !flex !w-full !h-auto !object-cover !mb-4 !rounded; }
    }
    .sz-speaker__name { @apply !text-xl !uppercase !font-semibold !tracking-wide !text-chi-bright-orange !font-display !text-left; }
    .sz-speaker__tagline { @apply !text-base !text-white !text-left; }
  }
}

.schedule {
  #sessionize.sessionize-wrapper {
    .sz-tabs {
      @apply flex !flex-row !justify-center;
      .sz-tabs__item .sz-tabs__link {
        @apply md:!text-base font-body inline-block !bg-chi-bright-blue !rounded-full hover:!bg-chi-light-blue hover:!text-chi-dark-blue text-white;
      }
      .sz-tabs__item--active .sz-tabs__link { @apply !bg-chi-bright-orange hover:!bg-chi-light-blue hover:!text-chi-dark-blue text-chi-dark-blue; }
    }
  
    .sz-day__title, .sz-cssgrid__time-label {  @apply text-white !z-0; }
    .sz-cssgrid__time-label {  @apply mt-4 md:mt-0; }
    .sz-day__title { @apply !text-base md:!text-left md:!ml-16; }

    .sz-cssgrid__track-label {
      @apply !text-white !text-center !font-display !uppercase !z-0 mb-2;
      &.sz-room--51249 { @apply !bg-chi-bright-blue/30 !mr-1; }
      &.sz-room--53623 { @apply !bg-chi-bright-orange/10 !mr-1; }
      &.sz-room--53624 { @apply !bg-chi-bright-blue/50 !mr-1; }
      &.sz-room--53625 { @apply !bg-chi-bright-orange/20; }
      &.sz-room--51248 { @apply !bg-chi-bright-blue !mr-1; }
    }

   
    .sz-cssgrid {
      @apply !gap-1;
      .sz-session--plenum {
        .sz-session__card {
          @apply !z-0 !font-body;
        }
      }
      .sz-cssgrid__time-separator { @apply border-chi-bright-blue; }
      .sz-session--service { @apply !z-0; }
      .sz-session {
        @apply !z-0;
        .sz-session__card {
          @apply !font-body !h-full !z-0;
          .sz-session__speakers  {
            @apply !w-fit mx-auto !text-center md:!text-left;
            a { @apply !text-base font-normal uppercase !font-display text-chi-bright-orange; }
          }
          .sz-session__title { @apply !font-body font-semibold !text-lg normal-case !leading-tight mb-2 text-center md:text-left; }
        }
        .sz-session__meta-group { @apply flex flex-col-reverse gap-2 md:flex-row-reverse justify-between w-full items-center mb-4; }
        .sz-session__time { @apply !text-base !text-white opacity-100; }
        .sz-session__room {
          @apply !rounded-full leading-none text-sm px-2 py-1 md:!px-4 md:py-2 !font-body normal-case tracking-normal !bg-chi-light-blue font-medium !text-chi-dark-blue text-center;
        }
      }
    }
  }
}

#sessionize.sessionize-wrapper .sz-cssgrid .sz-session__card { @apply !shadow !shadow-chi-dark-blue; }

.sz-session--service .sz-session__card { @apply !z-0; }

.sz-room--51249 {  
  .sz-session__card {
    @apply !bg-chi-bright-blue/30 md:!mr-1 !z-0 md:!mb-0 !mb-2;
    .sz-session__meta-group { @apply !mb-2 md:!flex-row; }
  }
}
.sz-room--53623 {  
  .sz-session__card {
    @apply !bg-chi-bright-orange/10 md:!mr-1 !z-0 md:!mb-0 !mb-2;
    .sz-session__meta-group { @apply md:!flex-row; }
  }
}
.sz-room--53624 {  
  .sz-session__card {
    @apply !bg-chi-bright-blue/50 md:!mr-1 !z-0 md:!mb-0 !mb-2;
    .sz-session__meta-group { @apply md:!flex-row; }
  }
}
.sz-room--53625 {  
  .sz-session__card {
    @apply !bg-chi-bright-orange/20 !z-0 md:!mb-0 !mb-2;
    .sz-session__meta-group { @apply md:!flex-row; }
  }
}

.sz-room--51248 { 
  .sz-session__card { @apply !bg-chi-bright-blue/80; }
}

.sz-room--51247 {
  .sz-session__card { @apply !bg-chi-platinum-blue/30; }
}

[data-sessionid='52889963-1c99-4ffb-8368-ec2b151b92cf'] { @apply !row-span-1; }
[data-sessionid='435015ea-f172-46ba-8c59-79c69b6398ac'], [data-sessionid='2126d95a-c13d-4515-8588-b11405827b87'], 
[data-sessionid='33559085-4078-4e33-880d-29186de7bfae']
{
  .sz-session__card { @apply !bg-chi-platinum-blue/30; }
}

.sz-modal-container {
  .sz-modal__close { @apply !text-2xl !text-white !w-20; }
  .sz-modal-container-inner {
    @apply !max-h-[90%];
    .sz-modal { @apply !mx-4 !bg-chi-dark-blue !text-white py-10;}
    .sz-modal-session {
      @apply !px-6 !pb-6 !pt-16 md:!p-12 !max-h-[700px] overflow-y-auto;
      .sz-modal-speakers, .sz-session__speakers {
        li { @apply !font-display !text-2xl !text-chi-bright-orange uppercase !mb-4; }
      }
      .sz-session__title { @apply !text-white !font-body !normal-case !mb-4;}
      .sz-session__description {@apply !text-base !font-body !mb-6 !text-white;}
      .sz-session__room { @apply !text-base !font-medium !rounded-full leading-none text-sm px-2 py-1 md:!px-4 md:py-2 !font-body !normal-case tracking-normal !bg-chi-light-blue font-medium !text-chi-dark-blue text-center justify-center w-full !mb-4; }
      .sz-session__time { @apply !text-base !bg-transparent !mx-2 justify-center w-full;}
    }
    .sz-modal-speaker {}
    .sz-speaker {
      @apply !px-6 !pb-6 md:!p-12 !pt-16 !max-h-[700px] overflow-y-auto;
      .sz-speaker__photo {
        @apply !float-none !max-w-full;
        img { @apply !w-full !h-80 object-cover !mb-6; }
      }
      .sz-speaker__name { @apply !font-display !text-2xl !text-chi-bright-orange; }
      .sz-speaker__tagline { @apply !text-lg !font-body !text-chi-light-blue; }
      .sz-speaker__sessions li { @apply !text-base !font-body !text-chi-light-blue !mb-2 last:!mb-0 !font-normal !pl-0 underline-offset-4 decoration-2 hover:underline decoration-chi-bright-orange;
      &:before { @apply !hidden; }
      }
      .sz-speaker__bio { @apply !text-base !font-body !mb-6 !text-white; }
    }
  }
}